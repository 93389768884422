<!-- 组件说明 -->
<template>
    <div class="correct_list">
        <div class="title">
            {{ title }}
        </div>
        <div class="ls_flexalign_center search_part">
            <el-select v-model="params.status" placeholder="全部状态" size="medium">
                <el-option v-for="dict in statusList" :key="dict.value" :label="dict.name" :value="dict.value" />
            </el-select>
            <el-select v-model="params.groupId" placeholder="全部标签" size="medium">
                <el-option v-for="dict in courseList" :key="dict.id" :label="dict.name" :value="dict.id" />
            </el-select>
            <el-input v-model.trim="params.studentName" size="medium" placeholder="请输入学生姓名"></el-input>
            <el-button size="medium" type="primary" @click="(params.pageIndex = 1), getList()">
                查询
            </el-button>
            <!-- <div class="ml10">
        <el-button
          style="width: 96px"
          class="dz-primary"
          size="medium"
          type="warning"
          plain
          @click="toCorrect('', 2)"
          >批量批阅</el-button
        >
      </div> -->
        </div>
        <div class="data_list">
            <el-table class="pd20" ref="multipleTable" :data="workList" highlight-current-row :header-cell-style="{ background: '#FAFAFA' }" style="width: 100%" :row-key="getRowKeys" @selection-change="handleSelectionChange">
                <el-table-column :key="1" v-model="seleChecked" type="selection" width="55" align="center" :reserve-selection="true" :selectable="selected" />
                <el-table-column label="姓名" width="460px">
                    <template slot-scope="scope">
                        <span>{{ scope.row.studentName }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="状态" width="560px">
                    <template slot-scope="scope">
                        <span :class="scope.row.status == 1
                                ? 'successFont'
                                : 'redFont'
                            ">
                            {{ scope.row.status == 1 ? "已提交" : "未提交" }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" class-name="status-col" min-width="100px">
                    <template slot-scope="scope">
                        <div class="opt_part">
                            <span v-if="scope.row.status != 1" class="colorEF curp" @click="
                                send([
                                    {
                                        groupId: scope.row.groupId,
                                        studentId: scope.row.id,
                                    },
                                ])
                                ">
                                {{ scope.row.isSend ? "再次发送" : "发送提醒" }}
                            </span>
                            <span v-else>--</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page_part">
                <el-button class="pl_btn" size="medium" type="success" @click="sendBatch">
                    批量提醒
                </el-button>
                <el-pagination v-show="total > 0" :current-page="params.pageIndex" :page-sizes="[10, 15, 20, 25]" :page-size="params.pageSize" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data () {
        return {
            schoolworkId: "",
            title: "",
            courseList: [],
            classList: [],
            params: {
                pageIndex: 1,
                pageSize: 10,
                schoolworkId: "",
                studentName: "",
                status: -1,
                groupId: -1,
            },
            statusList: [
                {
                    name: "全部状态",
                    value: -1,
                },
                {
                    name: "未提交",
                    value: 0,
                },
                {
                    name: "已提交",
                    value: 1,
                },
            ],
            total: null,
            workList: [],
            multipleSelection: [],
            seleChecked: false,
            ids: [],
        };
    },
    //监控data中的数据变化
    watch: {},
    //监听属性 类似于data概念
    computed: {},
    methods: {
        getClass (isChild) {
            this.$ajax
                .post("/user/group/getList", {})
                .then(response => {
                    this.courseList = JSON.parse(JSON.stringify(response.data));
                    this.classList = JSON.parse(JSON.stringify(response.data));
                    this.courseList.unshift({ name: "全部标签", id: -1 });
                })
                .catch(err => { });
        },
        handleSizeChange (val) {
            this.params.pageIndex = 1;
            this.params.pageSize = val;
            this.getList();
        },
        handleCurrentChange (val) {
            this.params.pageIndex = val;
            this.getList();
        },
        getList () {
            this.$ajax
                .post("/lv/schoolworkRecord/getRemindList", this.params)
                .then(response => {
                    this.workList = response.data.list;
                    this.total = response.data.total;
                    this.workList.forEach(item => {
                        const typeIndex = this.multipleSelection.findIndex(
                            v => {
                                if (v == item.id) {
                                    return v;
                                }
                                return v;
                            }
                        );
                        if (typeIndex == -1) {
                            item["checked"] = false;
                        } else {
                            item["checked"] = true;
                        }
                    });

                    this.getSelect();
                });
        },
        toWork () {
            this.$router.push({
                path: "/homeWork",
                query: { type: "0" },
            });
        },
        handleSelectionChange (val) {
            const selected = val;
            const arr = [];
            selected.forEach(item => {
                if (item.id) {
                    arr.push({ groupId: item.groupId, studentId: item.id });
                }
            });
            this.ids = arr;
            this.multipleSelection = arr;
            if (this.multipleSelection.length == 0) {
                this.seleChecked = false;
                return;
            }
            this.getSelect();
        },
        setSelect () {
            this.ids = [];
            this.multipleSelection = [];
            this.seleChecked = false;
            this.$refs.multipleTable.clearSelection();
        },
        getSelect () {
            const that = this;
            // 若有一项没有选中则将checkbox状态改为未选中
            that.workList.forEach(item => {
                const typeIndex = that.multipleSelection.findIndex(v => {
                    if (v.studentId == item.id) {
                        return v;
                    }
                    return v;
                });
                if (typeIndex == -1) {
                    item["checked"] = false;
                } else {
                    item["checked"] = true;
                }
            });
            for (let i = 0; i < that.workList.length; i++) {
                if (that.workList[i].checked == false) {
                    that.seleChecked = false;
                    break;
                } else {
                    that.seleChecked = true;
                }
            }
        },
        getRowKeys (row) {
            return row.id;
        },
        sendBatch () {
            if (this.ids.length === 0) {
                this.$message.warning("请先选择学生");
                return;
            }
            this.send(this.ids);
        },
        send (ids) {
            const h = this.$createElement;
            this.$confirm("", {
                message: h("div", null, [
                    h("i", {
                        class: "el-icon-warning",
                        style: "color:#FFAA00;font-size:24px;",
                    }),
                    h(
                        "span",
                        {
                            style: "margin-left:12px;font-size:16px;line-height:24px;vertical-align: top;color:#333333;font-family: MicrosoftYaHeiUI;",
                        },
                        ids.length > 1 ? "确定批量提醒？" : "确定发送提醒？"
                    ),
                    h(
                        "p",
                        {
                            style: "font-size: 12px;color: #999999;margin-left:34px;",
                        },
                        "学生可在公众号上收到您发出的作业提醒"
                    ),
                ]),
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                // type: "warning",
                closeOnClickModal: false,
                showClose: false,
                customClass: "custom-dialog",
            }).then(() => {
                this.$ajax
                    .post("lv/schoolworkRecord/sendRemind", {
                        schoolworkId: this.params.schoolworkId,
                        remark: "",
                        list: ids,
                    })
                    .then(res => {
                        this.setSelect();
                        this.getList();
                        this.$confirm("", {
                            message: h("div", null, [
                                h("i", {
                                    class: "el-icon-success",
                                    style: "color:#52C41A;font-size:24px;",
                                }),
                                h(
                                    "span",
                                    {
                                        style: "margin-left:12px;font-size:16px;line-height:24px;vertical-align: top;color:#333333;font-family: MicrosoftYaHeiUI;",
                                    },
                                    "提醒成功"
                                ),
                                h(
                                    "p",
                                    {
                                        style: "font-size: 12px;color: #999999;margin-left:34px;",
                                    },
                                    "学生可在公众号上收到您发出的作业提醒"
                                ),
                            ]),
                            showCancelButton: false,
                            showConfirmButton: false,
                            confirmButtonText: "确认",
                            cancelButtonText: "取消",
                            // type: "warning",
                            closeOnClickModal: false,
                            showClose: true,
                            customClass: "custom-dialog",
                        });
                    });
            });
        },
        selected (row, index) {
            if (row.status == 1) {
                return false; //不可勾选
            } else {
                return true; //可勾选
            }
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created () {
        this.getClass();
        this.title = this.$route.query.name;
        this.params.schoolworkId = this.$route.query.id;
        this.getList();
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted () { },
};
</script>

<style lang="scss" scoped>
.search_part {
    margin-bottom: 20px;

    >* {
        +* {
            margin-left: 7px;
        }

        &:first-child,
        &:nth-child(2) {
            width: 120px;
        }

        &:nth-child(3) {
            width: 200px;
        }
    }
}

.title {
    font-size: 40px;
    color: rgb(141, 141, 141);
    margin-bottom: 30px;
}

.correct_list {
    padding: 60px 30px 0 0;

    & .el-tabs__nav-wrap {
        background-color: #ffffff;
        // padding-bottom: 9px;

        .el-tabs__nav-scroll {
            padding: 0 40px;

            .el-tabs__nav {
                margin-left: -23px;
            }

            .el-tabs__active-bar {
                width: 24px !important;
                margin-left: 20px;
                height: 4px;
                background: #f56c0b;
                border-radius: 3px;
            }

            .el-tabs__item {
                padding: 0 23px;
                height: 64px;
                line-height: 64px;
                font-size: 16px;
                font-weight: 400;
            }

            .el-tabs__item:hover {
                color: #f56c0b;
            }

            .el-tabs__item.is-active {
                color: #f56c0b;
            }
        }
    }

    & .el-tabs__nav-wrap::after {
        background-color: #eee;
        height: 1px;
    }

    .noBottom {
        .el-tabs__active-bar {
            background: none !important;
        }
    }

    .el-button--warning {
        background: #fff;
        color: #f56c0b;
        border-color: #f56c0b;

        &:hover {
            background: #f56c0b !important;
            color: #fff;
        }

        &:focus {
            background: #f56c0b !important;
            color: #fff;
        }
    }
}

.written {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.page_part {
    height: 25px;
    margin-bottom: 10px;
    margin-top: 15px;
    padding: 10px 20px !important;
    text-align: center;
    position: relative;
}

.pl_btn {
    position: absolute;
    left: 0;
}
</style>
